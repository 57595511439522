:root {
  --silverPolish000: #ffffff;
  --silverPolish100: #f9f9f9;
  --silverPolish200: #efefef;
  --silverPolish300: #dedede;
  --silverPolish400: #cecece;
  --silverPolish500: #aaaaaa;
  --silverPolish600: #717171;
  --silverPolish700: #393939;
  --silverPolish800: #252525;

  --blueDacnis100: #ecfcfd;
  --blueDacnis200: #c9f5f8;
  --blueDacnis300: #93ecf1;
  --blueDacnis400: #5ee2ea;
  --blueDacnis500: #39bec5;
  --blueDacnis600: #267e84;
  --blueDacnis700: #133f42;

  --warmPink100: #ffeef3;
  --warmPink200: #fecddc;
  --warmPink300: #fd9bb9;
  --warmPink400: #fc6a96;
  --warmPink500: #d84572;
  --warmPink600: #902e4c;
  --warmPink700: #481726;

  --backgroundColorBlue: #AFD4FF;
  --backgroundColorOrange: #FFDB94;

  --dark: #1E1E1E;

  --boldColor: #000000;
}
