@import "src/styles/responsive";

.wrapper {
  width: 100%;
  height: 64px;
  color: var(--silverPolish800);
  display: flex;
  align-items: center;
}

.webHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.webHeader a:first-child,
.homeTitle {
  font-family: "Space Grotesk";
  font-size: 21px;
}

.webHeader a:last-child {
  font-family: "Space Grotesk";
  font-size: 16px;
}

.mobileHeader {
  display: none;
}

@include device(tablet) {
  .webHeader {
    display: none;
  }

  .mobileHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
