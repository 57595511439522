.ant-select-selector {
  border: none !important;
  border-radius: 24px !important;
  font-size: 16px !important;
  background: var(--silverPolish000);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.04);
}

.ant-select-selector input {
  font-size: 16px !important;
}

.ant-select .ant-select-arrow {
  left: 16px;
}

.ant-select-selector .ant-select-selection-search-input {
  margin-left: 26px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  margin-left: 26px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  margin-left: 26px !important;
}
.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  width: 90% !important;
}

.rc-virtual-list-holder {
  max-height: 190px !important;
}
