@import "src/styles/responsive";

html,
body {
  max-width: 1464px;
  width: 100%;
  height: 100%;
  margin: auto;
  font-family: "Inter";
  color: var(--silverPolish600);
  background: var(--silver-polish-100, #f9f9f9);
  background-color: transparent;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
  max-width: 1394px;
  min-height: 100vh;
  margin: 0 36px;
  background-color: transparent;
}

.children {
  flex: 1;
}

h1 {
  color: var(--silverPolish800);
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
}

h2 {
  color: var(--silverPolish800);
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
}

h3 {
  color: var(--silverPolish700);
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
}

a {
  text-decoration: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: var(--silverPolish800);
}

@include device(tablet) {
  .bodyWrapper {
    margin: 0 12px;
  }
}

::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: var(--silverPolish400);
  border-radius: 10px;
}
