@import "src/styles/responsive";

.wrapper {
  display: flex;
  align-items: center;
  border-radius: 24px;
  padding: 12px;
  background: var(--warmPink500);
  border: none;
  font-family: "Space Grotesk";
  color: var(--silverPolish000);
  font-size: 16px;
  gap: 4px;
  &:hover {
    background-color: var(--warmPink600);
    cursor: pointer;
  }
}

@include device(tablet) {
  .wrapper {
    width: 100%;
  }
}
