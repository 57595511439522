.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.inputEnterJob {
  width: 190px;
  height: 40px;
  padding: 10px 16pxpx 11px 16px;
  align-items: center;
  border-radius: 24px;
  border: none;
  background: var(--silverPolish000);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.04);
  font-size: 16px;
  color: var(--silverPolish500);
}

.searchButton {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 1111px;
  padding: 8px;
  background: var(--warmPink500);
  &:hover {
    background-color: var(--warmPink600);
    cursor: pointer;
  }
}
