@import "src/styles/responsive";

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}
.wrapperInput {
  min-width: 240px;
  height: 48px;
  border-radius: 24px;
  border: none;
  background-color: var(--silverPolish000);
}
.selectJob {
  width: 240px;
  height: 48px;
  border-radius: 24px;
  border: none;
  background: var(--silverPolish000);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.04);
  font-size: 16px;
  color: var(--silverPolish500);
}

.inputLocation {
  width: 240px;
  height: 48px;
  align-items: center;
  border-radius: 24px;
  border: none;
  background: var(--silverPolish000);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.04);
  font-size: 16px;
  color: var(--silverPolish500);
}

@include device(tablet) {
  .inputLocation {
    width: 100%;
  }
  .selectJob {
    width: 100%;
  }
}
