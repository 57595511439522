@import "src/styles/responsive";

.wrapper {
  height: 225px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footerNavigationWrapper {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 24px);
  gap: 16px;
}

.footerNavigationWrapper a:nth-child(1) {
  grid-column: 1;
  grid-row: 1 / 5;
  font-family: "Space Grotesk";
  font-size: 21px;
  font-style: normal;
  line-height: 120%;
  color: var(--silverPolish800);
}

.footerNavigationItem {
  width: fit-content;
  height: fit-content;
}

@include device(tablet) {
  .wrapper {
    height: auto;
    margin-top: 83px;
    flex-direction: column;
    justify-content: center;
  }
  .footerNavigationWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footerNavigationItem:first-child {
    margin-bottom: 16px;
  }
  .footerNavigationItem:nth-child(5) {
    margin-bottom: 80px;
  }
  .footerNavigationItem:last-child {
    margin-bottom: 32px;
  }
}
